import React from 'react'

const Intro = () => {
  return (
    <div className='intro' id='intro'>
      <div className='intro__container'>
        <div className='intro__gap pc-only'></div>

        <div className='intro__left'>
          <div className='omoty'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              width={520}
              height='485.663'
              viewBox='0 0 520 485.663'
              aria-label='omoty'
            >
              <defs>
                <clipPath id='clip-path-intro'>
                  <rect id='Rectangle_23' data-name='Rectangle 23' width={520} height='485.663' fill='#3b720e' />
                </clipPath>
              </defs>
              <g id='Group_45' data-name='Group 45' clipPath='url(#clip-path-intro)'>
                <path
                  id='Path_215'
                  data-name='Path 215'
                  d='M39.291,194.306c-5.234-.688-8.679-4.46-7.985-9.742.692-5.259,5-8.06,10.235-7.371,5.21.686,8.672,4.507,7.982,9.766-.7,5.282-5.02,8.032-10.231,7.347m1.922-14.625c-4.014-.527-7.32,1.187-7.841,5.154s2.225,6.5,6.24,7.029c3.991.525,7.276-1.217,7.8-5.184s-2.2-6.474-6.2-7'
                  transform='translate(4.649 26.371)'
                  fill='#3b720e'
                />
                <path
                  id='Path_216'
                  data-name='Path 216'
                  d='M33.259,174.483l.531-2.476,12.3-4.746c1.451-.56,3.836-1.187,3.836-1.187l.016-.069s-2.279-.319-3.714-.627l-10.53-2.258.477-2.221,17.217,3.691-.526,2.453-12.291,4.722C39.1,172.322,36.747,173,36.747,173l-.01.046s2.275.342,3.663.64l10.553,2.262-.477,2.222Z'
                  transform='translate(4.953 23.96)'
                  fill='#3b720e'
                />
                <path
                  id='Path_217'
                  data-name='Path 217'
                  d='M36.815,158.547l3.515-12.019,2.045.6-2.837,9.7,5.112,1.5,2.564-8.771,1.931.565-2.564,8.77,5.7,1.668,2.91-9.952,2.113.618L53.719,163.49Z'
                  transform='translate(5.482 21.82)'
                  fill='#3b720e'
                />
                <path
                  id='Path_218'
                  data-name='Path 218'
                  d='M42.632,140.186l5.21-13,1.976.793-2.155,5.382,14.366,5.755-.907,2.263-14.366-5.756-2.147,5.36Z'
                  transform='translate(6.349 18.939)'
                  fill='#3b720e'
                />
                <path
                  id='Path_219'
                  data-name='Path 219'
                  d='M48.4,126.008l1.056-2.171,6.663,3.243,3.979-8.172-6.663-3.243,1.068-2.192,15.833,7.709-1.066,2.192-7.407-3.606-3.979,8.173,7.407,3.605-1.057,2.17Z'
                  transform='translate(7.208 16.898)'
                  fill='#3b720e'
                />
                <path
                  id='Path_220'
                  data-name='Path 220'
                  d='M62.582,116.321c-4.542-2.69-6.226-7.512-3.513-12.095,2.7-4.563,7.764-5.446,12.307-2.755,4.522,2.677,6.2,7.552,3.5,12.115-2.715,4.583-7.772,5.413-12.3,2.734M70.1,103.63c-3.484-2.062-7.2-1.785-9.236,1.658s-.507,6.852,2.976,8.914c3.463,2.051,7.169,1.741,9.206-1.7s.517-6.82-2.946-8.871'
                  transform='translate(8.602 14.885)'
                  fill='#3b720e'
                />
                <path
                  id='Path_221'
                  data-name='Path 221'
                  d='M74.546,102.97l-9.717-6.838,1.39-1.974,9.542,6.715c2.361,1.662,4.313,1.067,5.934-1.235,1.566-2.226,1.467-4.264-.894-5.926L71.26,87l1.389-1.974,9.716,6.838c3.464,2.438,3.207,5.846.987,9-2.275,3.233-5.341,4.547-8.806,2.108'
                  transform='translate(9.654 12.661)'
                  fill='#3b720e'
                />
                <path
                  id='Path_222'
                  data-name='Path 222'
                  d='M82.267,91.38l1.51-1.852c2.233,1.455,4.477,1.025,6.5-1.451,1.481-1.815,1.751-3.794.1-5.139-1.779-1.451-3.486-.218-5.963,1.733-2.5,2.02-4.889,3.524-7.51,1.386-2.292-1.869-2.08-4.9.209-7.707,2.737-3.356,5.649-3.483,8.247-1.914l-1.48,1.815c-1.845-1.108-3.716-.649-5.285,1.276-1.466,1.8-1.689,3.57-.332,4.676,1.484,1.211,3.118.069,5.215-1.641,2.685-2.207,5.322-4.056,8.366-1.573,2.585,2.108,2.3,5.417-.108,8.369-3.111,3.814-6.741,4-9.465,2.022'
                  transform='translate(11.211 11.238)'
                  fill='#3b720e'
                />
                <path
                  id='Path_223'
                  data-name='Path 223'
                  d='M86.331,67.776l1.849-1.967,17.277,7.337-1.817,1.934L98.7,72.837,93.946,77.89l2.51,4.831-1.7,1.811ZM93.02,76.11l3.859-4.105L92.14,69.857c-1.648-.736-3.455-1.787-3.455-1.787l-.049.053s1.179,1.822,1.98,3.355Z'
                  transform='translate(12.856 9.8)'
                  fill='#3b720e'
                />
                <path
                  id='Path_224'
                  data-name='Path 224'
                  d='M93.846,60.943l1.867-1.713,12.778,3.247c1.509.384,3.817,1.253,3.817,1.253l.053-.048s-1.675-1.58-2.668-2.661l-7.282-7.937,1.674-1.536,11.909,12.977-1.85,1.7-12.761-3.263c-1.525-.4-3.835-1.2-3.835-1.2l-.036.031s1.658,1.6,2.618,2.642l7.3,7.954-1.674,1.537Z'
                  transform='translate(13.975 7.676)'
                  fill='#3b720e'
                />
                <path
                  id='Path_225'
                  data-name='Path 225'
                  d='M105.845,49.694l5.116-4.026c4.129-3.25,8.4-2.032,11.664,2.116,3.25,4.128,3.244,8.561-.9,11.826l-4.985,3.924Zm14.5,8.318c3.125-2.46,2.813-5.5.31-8.677s-5.34-4.412-8.484-1.939l-3.107,2.445L117.35,60.37Z'
                  transform='translate(15.762 6.524)'
                  fill='#3b720e'
                />
                <path
                  id='Path_226'
                  data-name='Path 226'
                  d='M133.075,42.819,122.231,37.6l2.277-1.449,5.523,2.769c1.292.665,3.224,1.847,3.224,1.847l.039-.025s-.232-2.263-.288-3.716l-.152-6.189,2.177-1.384.142,12.169,3.9,6.131-2.037,1.3Z'
                  transform='translate(18.202 4.386)'
                  fill='#3b720e'
                />
                <path
                  id='Path_227'
                  data-name='Path 227'
                  d='M135.782,28.87l11-5.972,1.017,1.87-8.879,4.821,2.54,4.678,8.026-4.358.959,1.767-8.026,4.358,2.834,5.22,9.106-4.945,1.05,1.934-11.227,6.1Z'
                  transform='translate(20.22 3.41)'
                  fill='#3b720e'
                />
                <path
                  id='Path_228'
                  data-name='Path 228'
                  d='M152.649,19.575l2.456-1.117L168.3,31.8l-2.413,1.1-3.721-3.95-6.312,2.871.488,5.421-2.262,1.029Zm3.023,10.248,5.128-2.332-3.567-3.786c-1.244-1.306-2.518-2.963-2.518-2.963l-.065.03s.4,2.132.557,3.855Z'
                  transform='translate(22.732 2.749)'
                  fill='#3b720e'
                />
                <path
                  id='Path_229'
                  data-name='Path 229'
                  d='M162.572,15.509l7.723-2.855c2.953-1.09,5.623-.009,6.632,2.721a4.139,4.139,0,0,1-1.492,5.244l.024.067c2.07-.134,3.013.779,4.047,3.1,1.159,2.523,1.676,3.24,2.092,3.339l.04.11-2.418.9c-.493-.171-.816-.91-1.969-3.486-1.018-2.273-2.306-2.755-4.459-1.959L168.4,24.306l2.544,6.88-2.263.838Zm5.152,6.976,4.727-1.747c2.242-.83,2.933-2.371,2.269-4.168-.713-1.931-1.993-2.594-4.323-1.733l-4.838,1.789Z'
                  transform='translate(24.209 1.822)'
                  fill='#3b720e'
                />
                <path
                  id='Path_230'
                  data-name='Path 230'
                  d='M179.114,21.978l2.3-.668c1.012,2.466,3.1,3.385,6.171,2.493,2.25-.655,3.594-2.13,3-4.175-.641-2.2-2.747-2.158-5.892-1.958-3.207.242-6.026.126-6.97-3.123-.826-2.839,1.07-5.215,4.546-6.226,4.158-1.21,6.629.339,7.877,3.1l-2.248.655c-.89-1.959-2.692-2.643-5.077-1.949-2.227.648-3.416,1.98-2.927,3.66.535,1.841,2.529,1.828,5.225,1.611,3.463-.293,6.685-.318,7.781,3.454.932,3.2-1.181,5.764-4.839,6.829-4.725,1.374-7.82-.535-8.94-3.709'
                  transform='translate(26.436 1.177)'
                  fill='#3b720e'
                />
                <path
                  id='Path_231'
                  data-name='Path 231'
                  d='M203.08,12.513c-.787-5.22,1.885-9.57,7.151-10.363,5.243-.789,9.125,2.575,9.911,7.794.781,5.2-1.934,9.577-7.176,10.367-5.265.793-9.1-2.6-9.885-7.8m14.58-2.2c-.6-4-3.163-6.7-7.119-6.109s-5.631,3.935-5.028,7.938c.6,3.979,3.18,6.653,7.135,6.058s5.61-3.909,5.012-7.887'
                  transform='translate(30.221 0.303)'
                  fill='#3b720e'
                />
                <path
                  id='Path_232'
                  data-name='Path 232'
                  d='M220.3,1.132l2.527-.175,7.962,10.508c.94,1.24,2.2,3.358,2.2,3.358l.071,0s-.323-2.279-.425-3.744L231.89.331l2.267-.156,1.213,17.569-2.5.172L224.929,7.409c-.942-1.264-2.247-3.333-2.247-3.333l-.047,0s.3,2.281.4,3.7l.743,10.769-2.267.156Z'
                  transform='translate(32.805 0.026)'
                  fill='#3b720e'
                />
                <path
                  id='Path_233'
                  data-name='Path 233'
                  d='M236.548,0l12.52.2-.034,2.13-10.106-.165-.087,5.325,9.135.149-.032,2.012-9.135-.149-.1,5.941,10.366.169-.036,2.2-12.781-.208Z'
                  transform='translate(35.183 0)'
                  fill='#3b720e'
                />
                <path
                  id='Path_234'
                  data-name='Path 234'
                  d='M255.726.491,269.675,1.85l-.207,2.121L263.7,3.408l-1.5,15.409-2.427-.236,1.5-15.41-5.749-.56Z'
                  transform='translate(38.051 0.073)'
                  fill='#3b720e'
                />
                <path
                  id='Path_235'
                  data-name='Path 235'
                  d='M271.291,2.082l2.38.407-1.246,7.3,8.96,1.529,1.247-7.3,2.4.41-2.961,17.359-2.4-.409,1.384-8.121-8.96-1.528-1.385,8.121-2.381-.407Z'
                  transform='translate(39.959 0.31)'
                  fill='#3b720e'
                />
                <path
                  id='Path_236'
                  data-name='Path 236'
                  d='M284.5,13.147c1.31-5.113,5.465-8.08,10.625-6.758,5.137,1.316,7.4,5.926,6.092,11.04-1.3,5.091-5.512,8.069-10.648,6.753-5.161-1.321-7.372-5.943-6.069-11.035m14.286,3.659c1-3.921-.3-7.407-4.178-8.4s-6.719,1.432-7.723,5.352c-1,3.9.337,7.368,4.212,8.361s6.69-1.414,7.688-5.313'
                  transform='translate(42.311 0.905)'
                  fill='#3b720e'
                />
                <path
                  id='Path_237'
                  data-name='Path 237'
                  d='M300.716,20.768,304.57,9.529l2.284.784-3.785,11.037c-.938,2.732.178,4.441,2.842,5.355,2.575.882,4.5.217,5.441-2.514l3.785-11.037,2.283.784-3.854,11.238c-1.375,4.007-4.719,4.712-8.369,3.461-3.738-1.282-5.856-3.86-4.482-7.868'
                  transform='translate(44.718 1.419)'
                  fill='#3b720e'
                />
                <path
                  id='Path_238'
                  data-name='Path 238'
                  d='M313.838,25.1l2.2.935c-.776,2.552.264,4.588,3.205,5.838,2.157.916,4.134.623,4.966-1.339.9-2.113-.763-3.41-3.327-5.245-2.639-1.842-4.75-3.716-3.426-6.833,1.157-2.723,4.13-3.364,7.463-1.949,3.989,1.695,4.924,4.459,4.141,7.393l-2.157-.917c.55-2.082-.412-3.751-2.7-4.723-2.135-.908-3.9-.628-4.585.985-.749,1.765.8,3.016,3.031,4.554,2.869,1.965,5.382,3.983,3.845,7.6-1.3,3.072-4.564,3.72-8.073,2.23-4.533-1.926-5.722-5.363-4.582-8.532'
                  transform='translate(46.675 2.35)'
                  fill='#3b720e'
                />
                <path
                  id='Path_239'
                  data-name='Path 239'
                  d='M337.872,22.593l2.4,1.228-2.249,18.633-2.358-1.209.779-5.37-6.174-3.158-3.94,3.752-2.213-1.132Zm-6.148,8.74,5.015,2.566.747-5.148c.249-1.787.755-3.814.755-3.814l-.063-.032s-1.423,1.638-2.673,2.833Z'
                  transform='translate(48.266 3.364)'
                  fill='#3b720e'
                />
                <path
                  id='Path_240'
                  data-name='Path 240'
                  d='M346.871,27.649l2.163,1.318.43,13.173c.051,1.554-.144,4.011-.144,4.011l.061.038s1.053-2.047,1.815-3.3l5.6-9.2,1.939,1.183-9.157,15.037-2.141-1.3-.41-13.161c-.038-1.575.091-4.018.091-4.018l-.04-.024s-1.072,2.035-1.811,3.247l-5.611,9.218-1.942-1.182Z'
                  transform='translate(50.291 4.117)'
                  fill='#3b720e'
                />
                <path
                  id='Path_241'
                  data-name='Path 241'
                  d='M360.736,36.193l5.285,3.8c4.264,3.068,4.278,7.507,1.2,11.791-3.068,4.266-7.327,5.488-11.612,2.408l-5.148-3.7ZM356.76,52.425c3.228,2.321,6.059,1.18,8.423-2.106s2.759-6.351-.488-8.687l-3.209-2.306L353.667,50.2Z'
                  transform='translate(52.189 5.39)'
                  fill='#3b720e'
                />
                <path
                  id='Path_242'
                  data-name='Path 242'
                  d='M374.724,60.352l2.008-11.86,2.022,1.785-1.129,6.072c-.28,1.425-.88,3.609-.88,3.609l.035.031s2.109-.853,3.489-1.307l5.9-1.861,1.934,1.707-11.649,3.51-4.807,5.447-1.81-1.6Z'
                  transform='translate(55.074 7.221)'
                  fill='#3b720e'
                />
                <path
                  id='Path_243'
                  data-name='Path 243'
                  d='M389.364,59.163l8.8,8.912-1.515,1.5-7.1-7.195-3.791,3.743,6.422,6.5-1.433,1.414-6.421-6.5-4.228,4.176,7.285,7.378-1.566,1.548-8.984-9.1Z'
                  transform='translate(56.116 8.81)'
                  fill='#3b720e'
                />
                <path
                  id='Path_244'
                  data-name='Path 244'
                  d='M403,72.938l1.757,2.049-9.147,16.388-1.727-2.012,2.76-4.674-4.515-5.265-5.07,1.978-1.618-1.887Zm-9,5.756,3.667,4.276,2.644-4.481c.909-1.559,2.145-3.243,2.145-3.243l-.046-.054s-1.937.977-3.548,1.608Z'
                  transform='translate(57.397 10.862)'
                  fill='#3b720e'
                />
                <path
                  id='Path_245'
                  data-name='Path 245'
                  d='M409.495,81.253l4.9,6.619c1.871,2.53,1.579,5.4-.761,7.128a4.141,4.141,0,0,1-5.453.031l-.056.043c.707,1.95.093,3.11-1.844,4.75-2.1,1.819-2.642,2.515-2.622,2.941l-.1.07-1.534-2.073c.028-.52.646-1.037,2.8-2.865,1.9-1.611,2-2.983.637-4.828L402.676,89.3l-5.9,4.364-1.436-1.939Zm-5.259,6.895,3,4.051c1.422,1.922,3.1,2.155,4.637,1.014,1.655-1.225,1.934-2.638.455-4.635l-3.069-4.147Z'
                  transform='translate(58.872 12.1)'
                  fill='#3b720e'
                />
                <path
                  id='Path_246'
                  data-name='Path 246'
                  d='M407.7,98.89l1.283,2.016c-2.083,1.664-2.38,3.929-.665,6.625,1.259,1.977,3.052,2.855,4.85,1.712,1.936-1.233,1.3-3.243.23-6.208-1.13-3.011-1.808-5.75,1.047-7.568,2.5-1.589,5.308-.432,7.253,2.623,2.325,3.654,1.532,6.46-.777,8.434l-1.258-1.977c1.631-1.4,1.784-3.324.45-5.421-1.246-1.958-2.858-2.726-4.336-1.787-1.618,1.029-1.048,2.94-.083,5.469,1.251,3.243,2.177,6.329-1.137,8.44-2.815,1.791-5.868.479-7.914-2.736-2.644-4.153-1.676-7.659,1.057-9.623'
                  transform='translate(60.323 14.11)'
                  fill='#3b720e'
                />
                <path
                  id='Path_247'
                  data-name='Path 247'
                  d='M423.487,119.138c4.79-2.221,9.716-.879,11.957,3.953,2.23,4.811.091,9.482-4.7,11.7-4.767,2.21-9.737.835-11.968-3.975-2.24-4.831-.059-9.469,4.708-11.68m6.2,13.379c3.672-1.7,5.547-4.92,3.864-8.549s-5.359-4.3-9.031-2.6c-3.651,1.692-5.493,4.922-3.81,8.551s5.327,4.288,8.978,2.594'
                  transform='translate(62.231 17.586)'
                  fill='#3b720e'
                />
                <path
                  id='Path_248'
                  data-name='Path 248'
                  d='M439.479,132.707l.874,2.377-7.86,10.582c-.928,1.249-2.61,3.053-2.61,3.053l.025.067s2.1-.948,3.474-1.455l10.107-3.717.785,2.133-16.526,6.078-.866-2.355,7.868-10.559c.949-1.257,2.57-3.089,2.57-3.089l-.017-.045s-2.1.926-3.437,1.417l-10.128,3.725-.785-2.131Z'
                  transform='translate(62.984 19.762)'
                  fill='#3b720e'
                />
                <path
                  id='Path_249'
                  data-name='Path 249'
                  d='M444.938,147.9l3.45,12.038-2.049.586-2.785-9.716-5.119,1.468,2.517,8.783-1.933.554-2.518-8.783-5.71,1.637,2.855,9.967-2.116.605-3.52-12.288Z'
                  transform='translate(63.736 22.024)'
                  fill='#3b720e'
                />
                <path
                  id='Path_250'
                  data-name='Path 250'
                  d='M449.819,166.625l2.553,13.772-2.095.388-1.057-5.7-15.214,2.821-.444-2.4,15.214-2.821-1.053-5.676Z'
                  transform='translate(64.564 24.813)'
                  fill='#3b720e'
                />
                <path
                  id='Path_251'
                  data-name='Path 251'
                  d='M452.662,181.667l.268,2.4-7.363.824,1.01,9.034,7.363-.823.272,2.423-17.5,1.957-.27-2.423,8.187-.916-1.01-9.034-8.188.916-.269-2.4Z'
                  transform='translate(64.801 27.053)'
                  fill='#3b720e'
                />
                <path
                  id='Path_252'
                  data-name='Path 252'
                  d='M445.533,197.469c5.274-.164,9.279,3,9.443,8.323.165,5.3-3.633,8.754-8.907,8.918-5.25.163-9.28-3.049-9.444-8.347-.167-5.321,3.657-8.731,8.908-8.894m.458,14.734c4.044-.126,7.028-2.35,6.905-6.345s-3.242-6.055-7.288-5.928c-4.02.125-6.98,2.371-6.856,6.367s3.218,6.032,7.238,5.907'
                  transform='translate(65.019 29.405)'
                  fill='#3b720e'
                />
                <path
                  id='Path_253'
                  data-name='Path 253'
                  d='M442.906,215.029l11.872.554L454.665,218l-11.659-.545c-2.885-.133-4.213,1.414-4.345,4.229-.128,2.719,1.051,4.386,3.936,4.522l11.66.545-.114,2.411-11.872-.555c-4.233-.2-5.847-3.211-5.665-7.067.184-3.949,2.066-6.705,6.3-6.508'
                  transform='translate(65.015 32.02)'
                  fill='#3b720e'
                />
                <path
                  id='Path_254'
                  data-name='Path 254'
                  d='M442.261,228.9l-.279,2.375c-2.667-.029-4.329,1.54-4.7,4.713-.273,2.328.561,4.142,2.677,4.391,2.28.269,3.06-1.689,4.1-4.665,1.027-3.049,2.233-5.6,5.6-5.2,2.938.346,4.387,3.019,3.963,6.617-.507,4.3-2.9,5.976-5.932,6.047l.274-2.328c2.152-.056,3.485-1.449,3.775-3.917.271-2.3-.492-3.92-2.231-4.125-1.9-.224-2.669,1.617-3.52,4.186-1.081,3.3-2.313,6.282-6.217,5.822-3.315-.39-4.851-3.335-4.4-7.121.576-4.89,3.541-7,6.9-6.79'
                  transform='translate(64.82 34.084)'
                  fill='#3b720e'
                />
                <path
                  id='Path_255'
                  data-name='Path 255'
                  d='M451.321,251.194l-.505,2.65-18.51,3.07.5-2.6,5.371-.757,1.3-6.811-4.706-2.73.465-2.44Zm-10.112-3.447-1.056,5.532,5.149-.727c1.786-.262,3.874-.346,3.874-.346l.013-.069s-1.972-.907-3.469-1.77Z'
                  transform='translate(64.376 35.973)'
                  fill='#3b720e'
                />
                <path
                  id='Path_256'
                  data-name='Path 256'
                  d='M449.377,261.16l-.659,2.446-12.53,4.1c-1.48.485-3.894.987-3.894.987l-.019.068s2.261.436,3.678.819l10.4,2.8-.592,2.194L428.757,270l.652-2.423,12.524-4.082c1.5-.479,3.882-1.038,3.882-1.038l.013-.046s-2.254-.46-3.626-.83l-10.424-2.806.59-2.194Z'
                  transform='translate(63.848 38.209)'
                  fill='#3b720e'
                />
                <path
                  id='Path_257'
                  data-name='Path 257'
                  d='M444.841,276.912l-2.169,6.136c-1.751,4.954-6.011,6.21-10.988,4.451-4.954-1.751-7.32-5.5-5.561-10.475l2.115-5.981Zm-16.7.724c-1.326,3.749.562,6.149,4.38,7.5s6.87.873,8.2-2.9l1.318-3.727-12.632-4.464Z'
                  transform='translate(63.37 40.362)'
                  fill='#3b720e'
                />
                <path
                  id='Path_258'
                  data-name='Path 258'
                  d='M425.719,296.967l11.953-1.374-1.151,2.44-6.147.607c-1.449.128-3.713.159-3.713.159l-.019.042s1.4,1.789,2.228,2.987l3.432,5.152-1.1,2.333L424.582,299.1,418.01,296l1.031-2.183Z'
                  transform='translate(62.248 43.754)'
                  fill='#3b720e'
                />
                <path
                  id='Path_259'
                  data-name='Path 259'
                  d='M430.973,310.879,424.862,321.8,423,320.764l4.933-8.818-4.646-2.6-4.459,7.971-1.757-.981,4.46-7.971-5.184-2.9-5.06,9.046-1.921-1.075,6.238-11.151Z'
                  transform='translate(60.961 45.014)'
                  fill='#3b720e'
                />
                <path
                  id='Path_260'
                  data-name='Path 260'
                  d='M421.108,328.029l-1.479,2.257-18.28-4.229,1.453-2.217,5.255,1.351,3.8-5.8-3.309-4.319,1.362-2.079Zm-8.03-7.046-3.088,4.71,5.038,1.294c1.751.439,3.712,1.158,3.712,1.158l.039-.059s-1.476-1.59-2.531-2.96Z'
                  transform='translate(59.766 46.61)'
                  fill='#3b720e'
                />
                <path
                  id='Path_261'
                  data-name='Path 261'
                  d='M415.395,336.443l-5,6.546c-1.912,2.5-4.749,3.016-7.063,1.248A4.145,4.145,0,0,1,401.786,339l-.055-.043c-1.677,1.221-2.964.954-5.077-.452-2.331-1.514-3.152-1.842-3.555-1.7l-.094-.071,1.566-2.051c.508-.119,1.176.333,3.529,1.893,2.076,1.378,3.424,1.1,4.818-.728l2.846-3.725-5.831-4.457,1.465-1.919Zm-8.087-3.141-3.062,4.008c-1.452,1.9-1.212,3.573.311,4.737,1.637,1.251,3.072,1.126,4.583-.848l3.133-4.1Z'
                  transform='translate(58.524 48.509)'
                  fill='#3b720e'
                />
                <path
                  id='Path_262'
                  data-name='Path 262'
                  d='M397.479,339.69l-1.581,1.792c-2.175-1.541-4.433-1.2-6.547,1.2-1.55,1.756-1.9,3.723-.3,5.13,1.721,1.52,3.474.354,6.025-1.5,2.578-1.922,5.022-3.332,7.559-1.093,2.219,1.957,1.888,4.979-.508,7.692-2.865,3.247-5.78,3.262-8.316,1.593l1.55-1.758c1.8,1.179,3.687.793,5.331-1.07,1.534-1.739,1.827-3.5.514-4.659-1.437-1.268-3.114-.192-5.275,1.436-2.769,2.1-5.476,3.845-8.422,1.247-2.5-2.208-2.086-5.5.433-8.36,3.256-3.689,6.891-3.733,9.536-1.651'
                  transform='translate(57.456 50.378)'
                  fill='#3b720e'
                />
                <path
                  id='Path_263'
                  data-name='Path 263'
                  d='M382.427,360.5c3.467,3.98,3.554,9.087-.463,12.585-4,3.484-9.08,2.731-12.546-1.248-3.452-3.962-3.518-9.117.479-12.6,4.017-3.5,9.078-2.7,12.53,1.264m-11.118,9.688c2.659,3.052,6.271,3.954,9.287,1.326s2.634-6.344-.024-9.4c-2.644-3.034-6.259-3.9-9.275-1.276s-2.631,6.312.013,9.346'
                  transform='translate(54.631 53.165)'
                  fill='#3b720e'
                />
                <path
                  id='Path_264'
                  data-name='Path 264'
                  d='M374.137,379.682l-2.037,1.5L359.741,376.6c-1.459-.54-3.661-1.65-3.661-1.65l-.057.041s1.5,1.748,2.369,2.93l6.4,8.662-1.828,1.351L352.5,373.77l2.017-1.49,12.339,4.6c1.473.561,3.686,1.6,3.686,1.6l.038-.028s-1.477-1.764-2.321-2.906l-6.412-8.681,1.828-1.35Z'
                  transform='translate(52.492 54.431)'
                  fill='#3b720e'
                />
                <path
                  id='Path_265'
                  data-name='Path 265'
                  d='M360.885,389.2,350.3,395.887l-1.137-1.8,8.54-5.4-2.843-4.5-7.722,4.878-1.074-1.7,7.721-4.88-3.172-5.019L341.855,383l-1.176-1.86,10.8-6.826Z'
                  transform='translate(50.732 55.741)'
                  fill='#3b720e'
                />
                <path
                  id='Path_266'
                  data-name='Path 266'
                  d='M343.665,399l-12.506,6.32-.962-1.9,5.176-2.617-6.982-13.815,2.176-1.1,6.982,13.815,5.155-2.6Z'
                  transform='translate(48.902 57.465)'
                  fill='#3b720e'
                />
                <path
                  id='Path_267'
                  data-name='Path 267'
                  d='M330.559,406.189l-2.228.931-2.854-6.838-8.388,3.5,2.854,6.837-2.249.94-6.783-16.253,2.25-.939,3.173,7.6,8.388-3.5-3.173-7.6,2.229-.929Z'
                  transform='translate(46.299 58.068)'
                  fill='#3b720e'
                />
                <path
                  id='Path_268'
                  data-name='Path 268'
                  d='M313.324,403.61c1.631,5.018-.293,9.749-5.357,11.394-5.043,1.638-9.422-1.045-11.052-6.065-1.623-5,.338-9.765,5.379-11.4,5.066-1.645,9.407,1.076,11.031,6.073M299.3,408.164c1.25,3.849,4.219,6.094,8.023,4.858s4.909-4.8,3.659-8.654c-1.242-3.826-4.227-6.043-8.031-4.805s-4.894,4.773-3.651,8.6'
                  transform='translate(44.132 59.127)'
                  fill='#3b720e'
                />
                <path
                  id='Path_269'
                  data-name='Path 269'
                  d='M295.366,406.041,298.12,417.6l-2.347.558-2.706-11.348c-.669-2.808-2.524-3.656-5.264-3-2.647.632-3.921,2.224-3.251,5.033l2.7,11.348-2.348.56-2.754-11.556c-.981-4.12,1.466-6.5,5.219-7.4,3.844-.916,7.013.128,7.994,4.249'
                  transform='translate(41.984 59.787)'
                  fill='#3b720e'
                />
                <path
                  id='Path_270'
                  data-name='Path 270'
                  d='M281.936,409.228l-2.358.387c-.71-2.568-2.676-3.733-5.829-3.215-2.312.38-3.824,1.684-3.478,3.786.373,2.266,2.469,2.472,5.615,2.65,3.214.144,6,.6,6.548,3.935.48,2.919-1.688,5.051-5.261,5.639-4.273.7-6.54-1.132-7.45-4.028l2.313-.379c.648,2.051,2.356,2.946,4.806,2.543,2.29-.377,3.631-1.557,3.347-3.285-.311-1.89-2.292-2.117-5-2.223-3.474-.125-6.675-.486-7.312-4.362-.541-3.292,1.864-5.584,5.623-6.2,4.858-.8,7.7,1.469,8.432,4.754'
                  transform='translate(39.88 60.208)'
                  fill='#3b720e'
                />
                <path
                  id='Path_271'
                  data-name='Path 271'
                  d='M263.348,423.968l-2.686.248-8.074-16.934,2.639-.243,2.215,4.95,6.9-.636,1.319-5.278,2.475-.227Zm.511-10.669-5.608.517,2.125,4.746c.746,1.643,1.4,3.625,1.4,3.625l.071-.007s.324-2.144.739-3.822Z'
                  transform='translate(37.614 60.436)'
                  fill='#3b720e'
                />
                <path
                  id='Path_272'
                  data-name='Path 272'
                  d='M252.986,424.773l-2.532.047-7.422-10.9c-.877-1.287-2.03-3.466-2.03-3.466h-.07s.207,2.292.235,3.759l.2,10.769-2.273.043-.325-17.61,2.509-.046,7.4,10.9c.877,1.308,2.077,3.441,2.077,3.441h.047s-.185-2.293-.212-3.713l-.2-10.792,2.273-.042Z'
                  transform='translate(35.556 60.633)'
                  fill='#3b720e'
                />
                <path
                  id='Path_273'
                  data-name='Path 273'
                  d='M236.8,424.963l-6.5-.365c-5.246-.3-7.644-4.035-7.346-9.305.3-5.246,3.233-8.566,8.5-8.269l6.333.359Zm-5.366-15.83c-3.971-.224-5.746,2.26-5.974,6.3s1.085,6.841,5.078,7.067l3.947.222.755-13.374Z'
                  transform='translate(33.197 60.609)'
                  fill='#3b720e'
                />
                <path
                  id='Path_274'
                  data-name='Path 274'
                  d='M211.8,412.434l4.677,11.089-2.667-.419-2.308-5.732c-.531-1.354-1.2-3.52-1.2-3.52l-.047-.006s-1.322,1.852-2.242,2.977l-3.982,4.742-2.549-.4,7.947-9.222,1.129-7.18,2.386.375Z'
                  transform='translate(30.004 60.275)'
                  fill='#3b720e'
                />
                <path
                  id='Path_275'
                  data-name='Path 275'
                  d='M200.359,421.061l-12.2-2.8.477-2.076,9.852,2.261,1.19-5.191-8.905-2.044.45-1.961,8.905,2.044,1.329-5.79-10.106-2.32.493-2.145L204.3,403.9Z'
                  transform='translate(28.019 59.72)'
                  fill='#3b720e'
                />
                <path
                  id='Path_276'
                  data-name='Path 276'
                  d='M180.854,416.315l-2.579-.79-1.049-18.735,2.534.775.172,5.424,6.632,2.029,3.222-4.385,2.377.727Zm4.522-9.681-5.386-1.649.166,5.2c.067,1.8-.076,3.888-.076,3.888l.068.02s1.113-1.861,2.135-3.257Z'
                  transform='translate(26.392 59.088)'
                  fill='#3b720e'
                />
                <path
                  id='Path_277'
                  data-name='Path 277'
                  d='M171.518,413.254l-7.682-2.977c-2.935-1.139-4.22-3.718-3.167-6.433a4.141,4.141,0,0,1,4.593-2.941l.025-.067c-1.641-1.27-1.743-2.579-.981-5,.8-2.658.889-3.54.645-3.888l.042-.11,2.4.932c.256.455.009,1.222-.832,3.918-.745,2.377-.1,3.593,2.043,4.422l4.37,1.694,2.652-6.842,2.252.873Zm.76-8.641-4.7-1.823c-2.229-.864-3.768-.165-4.462,1.622-.743,1.92-.224,3.264,2.095,4.163l4.811,1.865Z'
                  transform='translate(23.872 58.351)'
                  fill='#3b720e'
                />
                <path
                  id='Path_278'
                  data-name='Path 278'
                  d='M163.2,397.107l-2.162-1.02c.874-2.519-.084-4.593-2.973-5.956-2.119-1-4.1-.784-5.013,1.142-.98,2.076.628,3.436,3.118,5.37,2.564,1.942,4.6,3.9,3.155,6.957-1.261,2.675-4.257,3.2-7.531,1.655-3.918-1.849-4.744-4.645-3.847-7.546l2.119,1c-.63,2.058.267,3.763,2.514,4.824,2.1.989,3.872.779,4.619-.8.818-1.734-.683-3.045-2.848-4.668-2.79-2.075-5.221-4.189-3.544-7.743,1.425-3.018,4.7-3.537,8.152-1.912,4.452,2.1,5.506,5.58,4.243,8.7'
                  transform='translate(21.983 57.705)'
                  fill='#3b720e'
                />
                <path
                  id='Path_279'
                  data-name='Path 279'
                  d='M7.981,294.706c-5.2-.936-8.457-4.865-7.515-10.107.939-5.22,5.375-7.813,10.57-6.879,5.172.931,8.449,4.911,7.51,10.13-.942,5.242-5.393,7.785-10.565,6.856m2.61-14.515c-3.983-.717-7.367.84-8.075,4.777s1.916,6.6,5.9,7.314c3.961.714,7.324-.871,8.033-4.808s-1.9-6.57-5.858-7.283'
                  transform='translate(0.045 41.329)'
                  fill='#3b720e'
                />
                <path
                  id='Path_280'
                  data-name='Path 280'
                  d='M3.778,272.98l1-2.328,12.989-2.282c1.533-.269,4-.423,4-.423l.028-.065s-2.176-.754-3.525-1.333l-9.9-4.252.9-2.089,16.187,6.952-.992,2.306-12.978,2.26c-1.554.26-3.992.476-3.992.476l-.018.044s2.166.775,3.471,1.336l9.922,4.261-.9,2.089Z'
                  transform='translate(0.563 38.749)'
                  fill='#3b720e'
                />
                <path
                  id='Path_281'
                  data-name='Path 281'
                  d='M11.337,256.715,18.5,246.449l1.746,1.219-5.778,8.286L18.831,259l5.224-7.491,1.65,1.15-5.224,7.491,4.871,3.4,5.927-8.5,1.8,1.259-7.308,10.48Z'
                  transform='translate(1.688 36.7)'
                  fill='#3b720e'
                />
                <path
                  id='Path_282'
                  data-name='Path 282'
                  d='M25.52,239.016l2.462-1.922,8.777,5.277c1.739,1.074,4.793,3.254,4.793,3.254l.038-.029s-1.342-3.487-1.977-5.454l-3.05-9.75L39.1,228.41l10.838,13.878-1.827,1.428-6.556-8.394c-.962-1.232-2.67-3.65-2.67-3.65l-.038.029s1.072,2.526,1.507,3.929l3.485,11.423-1.491,1.165-10.239-6.149c-1.236-.776-3.447-2.413-3.447-2.413l-.037.029s1.934,2.244,2.9,3.475l6.555,8.394-1.716,1.34Z'
                  transform='translate(3.8 34.014)'
                  fill='#3b720e'
                />
                <rect
                  id='Rectangle_19'
                  data-name='Rectangle 19'
                  width='2.415'
                  height='17.615'
                  transform='translate(48.171 259.675) rotate(-28.565)'
                  fill='#3b720e'
                />
                <path
                  id='Path_283'
                  data-name='Path 283'
                  d='M47.916,222.965l2.246-.884L55.8,236.417l8.477-3.334.805,2.047-10.723,4.218Z'
                  transform='translate(7.135 33.071)'
                  fill='#3b720e'
                />
                <path
                  id='Path_284'
                  data-name='Path 284'
                  d='M61.472,218.279,73.759,215.9l.406,2.09-9.919,1.924,1.013,5.226,8.967-1.739.383,1.975-8.966,1.738,1.13,5.831,10.175-1.974.418,2.161-12.544,2.432Z'
                  transform='translate(9.154 32.15)'
                  fill='#3b720e'
                />
                <path
                  id='Path_285'
                  data-name='Path 285'
                  d='M88.415,223.952c.919-5.2,4.837-8.468,10.08-7.54,5.219.922,7.824,5.348,6.9,10.544-.913,5.172-4.883,8.459-10.1,7.536-5.242-.926-7.8-5.366-6.882-10.539m14.515,2.567c.7-3.983-.862-7.36-4.8-8.056s-6.589,1.934-7.293,5.918c-.7,3.96.893,7.319,4.83,8.015s6.561-1.915,7.262-5.877'
                  transform='translate(13.139 32.203)'
                  fill='#3b720e'
                />
                <path
                  id='Path_286'
                  data-name='Path 286'
                  d='M110.431,219.69l2.33.993,2.319,12.975c.275,1.531.435,3.993.435,3.993l.064.027s.748-2.177,1.322-3.527l4.22-9.907,2.091.891-6.9,16.2L114,240.35l-2.3-12.966c-.264-1.552-.487-3.988-.487-3.988l-.044-.02s-.769,2.168-1.325,3.474l-4.229,9.929-2.09-.89Z'
                  transform='translate(15.417 32.715)'
                  fill='#3b720e'
                />
                <path
                  id='Path_287'
                  data-name='Path 287'
                  d='M126.808,227.194l10.294,7.131-1.213,1.751-8.309-5.756-3.034,4.379,7.512,5.2-1.145,1.653-7.512-5.2-3.384,4.884,8.523,5.9-1.253,1.81-10.508-7.28Z'
                  transform='translate(17.39 33.833)'
                  fill='#3b720e'
                />
                <path
                  id='Path_288'
                  data-name='Path 288'
                  d='M145.074,241.337l1.93,2.455-5.249,8.79c-1.068,1.742-3.238,4.8-3.238,4.8l.029.037s3.482-1.353,5.446-1.993l9.737-3.08,1.989,2.53-13.84,10.878-1.432-1.823,8.37-6.58c1.228-.964,3.641-2.681,3.641-2.681l-.029-.036s-2.522,1.079-3.923,1.518l-11.407,3.52-1.17-1.487,6.114-10.254c.772-1.237,2.4-3.452,2.4-3.452l-.029-.037s-2.238,1.94-3.465,2.9l-8.37,6.58-1.346-1.711Z'
                  transform='translate(19.543 35.939)'
                  fill='#3b720e'
                />
                <rect
                  id='Rectangle_20'
                  data-name='Rectangle 20'
                  width='17.61'
                  height='2.414'
                  transform='translate(162.567 304.532) rotate(-28.723)'
                  fill='#3b720e'
                />
                <path
                  id='Path_289'
                  data-name='Path 289'
                  d='M160.251,263.663l.89,2.245-14.322,5.682,3.361,8.471-2.046.811-4.252-10.714Z'
                  transform='translate(21.426 39.263)'
                  fill='#3b720e'
                />
                <path
                  id='Path_290'
                  data-name='Path 290'
                  d='M165.3,277.228l2.421,12.288-2.091.412-1.953-9.919-5.226,1.03,1.766,8.966-1.975.389-1.766-8.966-5.83,1.149,2,10.174-2.161.426-2.47-12.544Z'
                  transform='translate(22.042 41.284)'
                  fill='#3b720e'
                />
                <path
                  id='Path_291'
                  data-name='Path 291'
                  d='M159.528,304.158c5.2.9,8.484,4.812,7.572,10.059-.906,5.223-5.325,7.842-10.525,6.938-5.176-.9-8.474-4.859-7.568-10.082.911-5.246,5.345-7.815,10.521-6.915m-2.523,14.526c3.987.693,7.36-.883,8.044-4.824s-1.954-6.584-5.941-7.277c-3.965-.688-7.317.916-8,4.856s1.936,6.557,5.9,7.245'
                  transform='translate(22.167 45.267)'
                  fill='#3b720e'
                />
                <path
                  id='Path_292'
                  data-name='Path 292'
                  d='M164.259,326.2l-.987,2.332-12.967,2.348c-1.532.278-3.991.445-3.991.445l-.027.066s2.178.742,3.53,1.313l9.915,4.2-.885,2.091-16.212-6.86.977-2.309,12.959-2.328c1.552-.268,3.987-.5,3.987-.5l.018-.043s-2.169-.764-3.477-1.318l-9.937-4.2.886-2.092Z'
                  transform='translate(21.24 47.554)'
                  fill='#3b720e'
                />
                <path
                  id='Path_293'
                  data-name='Path 293'
                  d='M156.833,342.571l-7.1,10.312-1.754-1.208,5.734-8.324-4.387-3.022-5.184,7.525-1.657-1.142,5.184-7.525-4.893-3.371-5.882,8.539-1.813-1.249,7.252-10.526Z'
                  transform='translate(20.115 49.526)'
                  fill='#3b720e'
                />
                <path
                  id='Path_294'
                  data-name='Path 294'
                  d='M143.09,360.884l-2.451,1.937-8.805-5.226c-1.745-1.064-4.812-3.225-4.812-3.225l-.038.029s1.362,3.479,2.008,5.441l3.107,9.731-2.525,2-10.917-13.813,1.82-1.439,6.6,8.355c.968,1.225,2.691,3.634,2.691,3.634l.038-.029s-1.087-2.52-1.529-3.92l-3.551-11.4,1.486-1.174,10.271,6.09c1.241.769,3.461,2.392,3.461,2.392l.037-.029s-1.945-2.234-2.915-3.458l-6.6-8.355,1.708-1.349Z'
                  transform='translate(17.67 51.684)'
                  fill='#3b720e'
                />
                <rect
                  id='Rectangle_21'
                  data-name='Rectangle 21'
                  width='2.415'
                  height='17.614'
                  transform='matrix(0.875, -0.484, 0.484, 0.875, 131.359, 411.778)'
                  fill='#3b720e'
                />
                <path
                  id='Path_295'
                  data-name='Path 295'
                  d='M119.862,376.126l-2.24.9-5.73-14.3-8.456,3.388-.819-2.043,10.7-4.287Z'
                  transform='translate(15.281 53.577)'
                  fill='#3b720e'
                />
                <path
                  id='Path_296'
                  data-name='Path 296'
                  d='M106.136,381.215l-12.278,2.448-.417-2.09,9.912-1.975-1.042-5.223-8.959,1.788-.393-1.974,8.959-1.786-1.162-5.826L90.589,368.6l-.43-2.157,12.534-2.5Z'
                  transform='translate(13.426 54.197)'
                  fill='#3b720e'
                />
                <path
                  id='Path_297'
                  data-name='Path 297'
                  d='M79.348,375.522c-.892,5.2-4.794,8.5-10.044,7.6-5.228-.895-7.857-5.308-6.966-10.511.887-5.18,4.84-8.491,10.067-7.6,5.251.9,7.83,5.329,6.943,10.509m-14.537-2.491c-.684,3.991.9,7.36,4.845,8.035s6.582-1.97,7.266-5.96c.679-3.966-.933-7.316-4.876-7.992s-6.555,1.951-7.235,5.917'
                  transform='translate(9.258 54.334)'
                  fill='#3b720e'
                />
                <path
                  id='Path_298'
                  data-name='Path 298'
                  d='M57.674,380.324l-2.336-.98-2.392-12.961c-.283-1.531-.457-3.989-.457-3.989l-.065-.029s-.735,2.182-1.3,3.534l-4.164,9.931-2.094-.879,6.807-16.239,2.314.971,2.371,12.953c.272,1.551.509,3.986.509,3.986l.044.019s.757-2.173,1.306-3.481l4.173-9.953,2.1.878Z'
                  transform='translate(6.681 53.418)'
                  fill='#3b720e'
                />
                <path
                  id='Path_299'
                  data-name='Path 299'
                  d='M41.332,372.924,31,365.849l1.2-1.758,8.339,5.711,3.01-4.395-7.539-5.163,1.137-1.659,7.538,5.163,3.357-4.9-8.554-5.86,1.244-1.816,10.546,7.224Z'
                  transform='translate(4.616 52.294)'
                  fill='#3b720e'
                />
                <path
                  id='Path_300'
                  data-name='Path 300'
                  d='M23.525,359.233l-1.943-2.446,5.2-8.821c1.059-1.749,3.214-4.821,3.214-4.821l-.03-.037s-3.475,1.371-5.437,2.023l-9.724,3.132-2-2.52,13.787-10.956,1.443,1.815L19.7,343.23c-1.222.972-3.627,2.7-3.627,2.7l.029.038s2.517-1.094,3.917-1.541l11.393-3.581,1.179,1.482-6.063,10.29c-.766,1.243-2.384,3.467-2.384,3.467l.029.037s2.229-1.952,3.451-2.924l8.339-6.627,1.355,1.7Z'
                  transform='translate(1.907 49.855)'
                  fill='#3b720e'
                />
                <rect
                  id='Rectangle_22'
                  data-name='Rectangle 22'
                  width='17.609'
                  height='2.414'
                  transform='translate(10.76 388.243) rotate(-29.078)'
                  fill='#3b720e'
                />
                <path
                  id='Path_301'
                  data-name='Path 301'
                  d='M7.279,336.043l-.9-2.239,14.289-5.763-3.409-8.45,2.04-.824,4.312,10.69Z'
                  transform='translate(0.949 47.469)'
                  fill='#3b720e'
                />
                <path
                  id='Path_302'
                  data-name='Path 302'
                  d='M2.485,322.346,0,310.076l2.088-.423,2.006,9.9L9.312,318.5,7.5,309.546l1.972-.4,1.813,8.952,5.822-1.18-2.058-10.158,2.158-.438,2.537,12.526Z'
                  transform='translate(0 45.616)'
                  fill='#3b720e'
                />
              </g>
            </svg>
          </div>
        </div>

        <div className='intro__gap pc-only'></div>

        <div className='intro__right'>
          <div className='intro__top'>
            <div className='intro__head'>
              <h2 className='times-fonts'>
                One mile, <br />
                One thousand years.
              </h2>
              <p>
                1,000年先の未来を想像しながら、
                <br />
                身の回りから変化を。
              </p>
            </div>
          </div>

          <div className='intro__bottom'>
            <p className='intro__desc'>
              <span className='jp-fonts'>anew inc.</span> はプロダクトサステナビリティの観点から、
              <br />
              「私たちはいかにしてよき祖先となれるか」という
              <br />
              グッドアンセスターとしての可能性を追求する
              <br className='sp-only' />
              プロジェクトチームです。
            </p>
            <p className='intro__desc'>
              環境危機・⽂化・経済といったコンテクストへの
              <br className='sp-only' />
              考察を起点に、
              <br className='pc-only' />
              ものにまつわるデザインやマテリアルの
              <br className='sp-only' />
              リサーチを進め、
              <br className='pc-only' />
              これからの社会にふさわしい
              <br className='sp-only' />
              低環境負荷のプロダクトを
              <br className='pc-only' />
              研究開発していきます。
              <br className='sp-only' />
              <span className='jp-fonts'> All change anew.</span>
            </p>
          </div>
        </div>

        <div className='intro__gap pc-only'></div>
      </div>
    </div>
  )
}

export default Intro
